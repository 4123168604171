<template>
  <div
    class="flex items-center text-center separator"
    :class="[props.color, props.padding]"
  >
    <template v-if="props.word">
      {{ word }}
    </template>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    word?: string | null
    padding?: string
    color?: string
  }>(),
  {
    word: null,
    padding: 'py-8',
    color: 'text-gray-300'
  }
)
</script>

<style scoped>
.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid;
  border-color: rgb(209 213 219);
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
</style>
